import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
	useLazyGetNewCsvQuery,
	useLazyGetCsvAsyncQuery,
	useLazyGetPipedriveServiceQuery,
	useLazyGetHubspotServiceQuery
} from 'services';
import { Pagination, useTableSelection, TableSelection, Th, Tr, Table } from 'features/table';
import { Button, Checkbox, Icon, Text, openNotification } from 'shared/components/ui';
import { useFilters, useSearch, useGetCandidates } from 'features/search/hooks';
import { ProgAIProfile, ProgAIResults } from 'shared/generated-models';
import { ResultsEmpty, ResultsError, ResultsSkeleton, SearchUpdate, ProfileRow } from 'features/search/components';
import { BulkContactsModal, CandidateModal } from 'features/candidate';
import { ExportCsvButton } from 'features/csv-export';
import { Dropdown, Menu } from 'antd';
import { ExportPipeButton } from 'features/csv-export/components/ExportPipeButton';
import { ExportHubspotButton } from 'features/csv-export/components/ExportHubspotButton';
import { useGetCurrentUserQuery } from 'services';
import { IntergationsPushMenu } from 'features/integrations';
import { SuccessModal } from 'features/csv-export';
import { useSaveHistoryMutation } from 'services';
import { isDeepEqual } from 'shared/utils';

import styles from './index.module.scss';

function formatNumberWithCommas(number: number) {
	if (number > 300000) return '300,000+';
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const ResultsList = () => {
	const [candidate, setCandidate] = useState<ProgAIResults | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isCsvExportSuccessModalOpen, setIsCsvExportSuccessModalOpen] = useState(false);
	const [isPipeModalOpen, setIsPipeModalOpen] = useState(false);
	const [isHubspotModalOpen, setIsHubspotModalOpen] = useState(false);
	const [linkPipe, setLinkPipe] = useState(undefined);
	const [linkHubspot, setLinkHubspot] = useState(undefined);
	const [saveHistory] = useSaveHistoryMutation();
	const [isRevealModalOpen, setIsRevealModalOpen] = useState(false);

	const { data: user, refetch: refetchUser } = useGetCurrentUserQuery();

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const closeCsvExportSuccessModal = () => {
		setIsCsvExportSuccessModalOpen(false);
	};

	const openPipeModal = () => {
		setIsPipeModalOpen(true);
	};

	const closePipeModal = () => {
		setIsPipeModalOpen(false);
		setLinkPipe(undefined);
	};

	const openHubspotModal = () => {
		setIsHubspotModalOpen(true);
	};

	const closeHubspotModal = () => {
		setIsHubspotModalOpen(false);
		setLinkHubspot(undefined);
	};

	const location = useLocation();

	const search = useSearch();
	const filters = useFilters();
	const modifiedFilters = { ...filters };
	if ('headcount' in modifiedFilters) {
		const prevHeadcount = modifiedFilters['headcount'];
		const newHeadcount: any = [];
		// @ts-ignore
		prevHeadcount.forEach(item => {
			if (item === '10000+') {
				return newHeadcount.push([10000, null]);
			}
			return newHeadcount.push(item.split('-').map((str: string) => parseInt(str)));
		});
		modifiedFilters['headcount'] = newHeadcount;
	}

	if ('total_funding_start' in modifiedFilters || 'total_funding_end' in modifiedFilters) {
		// @ts-ignore
		modifiedFilters['total_funding_amount'] = [
			// @ts-ignore
			modifiedFilters['total_funding_start'] || null,
			// @ts-ignore
			modifiedFilters['total_funding_end'] || null
		];
		if ('total_funding_start' in modifiedFilters) delete modifiedFilters['total_funding_start'];
		if ('total_funding_end' in modifiedFilters) delete modifiedFilters['total_funding_end'];
	}
	if ('locations' in modifiedFilters) {
		if ('countries' in modifiedFilters) delete modifiedFilters['countries'];
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('countries' in modifiedFilters) {
		if ('subregions' in modifiedFilters) delete modifiedFilters['subregions'];
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}
	if ('subregions' in modifiedFilters) {
		if ('regions' in modifiedFilters) delete modifiedFilters['regions'];
	}

	const ref = useRef<HTMLDivElement>(null);

	const [getCsvAsync] = useLazyGetCsvAsyncQuery();
	const [getPipedriveData] = useLazyGetPipedriveServiceQuery();
	const [hasPipedriveError, setHasPipedriveError] = useState(false);
	const [getHubspotData] = useLazyGetHubspotServiceQuery();
	const [hasHubspotError, setHasHubspotError] = useState(false);
	const [isHistorySaved, setIsHistorySaved] = useState(false);
	const prevModifiedFiltersRef = useRef(modifiedFilters);

	const closeCandidateModal = () => {
		setCandidate(null);
	};

	useEffect(() => {
		ref.current?.scroll({ top: 0, behavior: 'smooth' });
	}, [filters.page]);

	const { data, isFetching, isError, refetch, totalLoaded } = useGetCandidates();

	useEffect(() => {
		const prevModifiedFilters = prevModifiedFiltersRef.current;
		if (data && data.count && (!isHistorySaved || !isDeepEqual(prevModifiedFilters, modifiedFilters))) {
			if (modifiedFilters.page === 1) {
				saveHistory({
					filters: modifiedFilters,
					leads_number: data.count
				});
				refetch();
			}
			setIsHistorySaved(true);
		}
		prevModifiedFiltersRef.current = modifiedFilters;
	}, [data, isHistorySaved, modifiedFilters]);

	const {
		selectAll,
		isAllSelected,
		toggle,
		isSelected,
		someSelected,
		count,
		selectPage,
		selectCount,
		included,
		excluded,
		togglePage,
		clearSelection,
		selectedCount,
		mode
	} = useTableSelection<ProgAIProfile>({
		data: data?.results.map(row => row),
		totalCount: data?.count,
		max: data?.count
	});

	useEffect(() => {
		if (location.state && location.state.refresh) {
			clearSelection();
		}
	}, [location.key]);

	const downloadCsv = (response: any) => {
		if (response.error) {
			const message = JSON.parse(response.error.data).detail;
			openNotification({ text: message, closable: true });
			setLoading(false);
			closeModal();
			return;
		}

		setLoading(false);
		closeModal();
		setIsCsvExportSuccessModalOpen(true);
		refetch();
		refetchUser();
	};

	const [countValue, setCountValue] = useState(0);

	const defaultTogglePage = () => {
		if (countValue || included.length || selectedCount) return;
		if (filters.page !== 1) {
			togglePage();
			return;
		}

		let count = 100;
		if (data?.count && data?.count < count) count = data?.count;
		if (user && user.credits < count) count = user.credits;
		selectCount(count);
		setCountValue(count);
	};

	const handleExportButtonClick = () => {
		defaultTogglePage();
	};

	const handleRevealPage = () => {
		setIsRevealModalOpen(true);
	};

	const closeRevealModal = () => {
		setIsRevealModalOpen(false);
	};

	const handleExport = (isFull: boolean) => {
		setLoading(true);
		if (included.length > 0) {
			getCsvAsync({
				filters: modifiedFilters,
				profile_id_list: included,
				all_fields: isFull
			}).then(downloadCsv);
		} else if (selectedCount > 0) {
			getCsvAsync({
				filters: modifiedFilters,
				limit: selectedCount || undefined,
				exclude_ids: excluded,
				all_fields: isFull
			}).then(downloadCsv);
		}
	};

	const handlePipedriveData = async () => {
		if (included.length > 3000 || selectedCount > 3000) {
			openNotification({ text: 'You can export up to 3000 leads', closable: true });
			return;
		}

		try {
			setLoading(true);
			setHasPipedriveError(false);
			let data;
			if (included.length > 0) {
				data = await getPipedriveData({ profile_id_list: included, all_fields: true });
			} else {
				data = await getPipedriveData({
					filters: modifiedFilters,
					limit: selectedCount || undefined,
					exclude_ids: excluded,
					all_fields: true
				});
			}
			setLinkPipe(data.data.redirect_url);
			setLoading(false);
		} catch (error) {
			console.error('error:', error);
			setLoading(false);
			throw error;
		}
	};

	const handleHubspotData = async () => {
		if (included.length > 3000 || selectedCount > 3000) {
			openNotification({ text: 'You can export up to 3000 leads', closable: true });
			return;
		}

		try {
			setLoading(true);
			setHasHubspotError(false);
			let data;
			if (included.length > 0) {
				data = await getHubspotData({ profile_id_list: included, all_fields: true });
			} else {
				data = await getHubspotData({
					filters: modifiedFilters,
					limit: selectedCount || undefined,
					exclude_ids: excluded,
					all_fields: true
				});
			}
			setLinkHubspot(data.data.redirect_url);
			setLoading(false);
		} catch (error) {
			console.error('error:', error);
			setLoading(false);
			throw error;
		}
	};

	const isHideRevealChecked = ("hide_revealed_leads" in modifiedFilters) && (modifiedFilters["hide_revealed_leads"] === "true");

	const handleCheckReveal = () => {
		if ("hide_revealed_leads" in modifiedFilters) {
			delete modifiedFilters['hide_revealed_leads'];
			// @ts-ignore
			const {hide_revealed_leads, ...otherFilters} = filters;
			search({ ...otherFilters, page: 1 }, { refresh: true });
		} else {
			// @ts-ignore
			modifiedFilters["hide_revealed_leads"] = true;
			search({ ...filters, hide_revealed_leads: true, page: 1 }, { refresh: true });
		}
	}

	if (isFetching) return <ResultsSkeleton />;

	if (isError || !data) return <ResultsError />;

	if (data.results.length === 0) return <ResultsEmpty />;

	const menu = (
		<Menu>
			<Menu.Item key="1">
				<ExportCsvButton
					type="ghost"
					handleExport={handleExport}
					disabled={!someSelected}
					candidates={{ count, profile_id_list: included, limit: selectedCount || undefined }}
					loading={loading}
					isModalOpen={isModalOpen}
					openModal={openModal}
					closeModal={closeModal}
				/>
			</Menu.Item>
			<Menu.Item key="2">
				<ExportPipeButton
					type="ghost"
					handlePipedriveData={handlePipedriveData}
					disabled={!someSelected}
					candidates={{ count, profile_id_list: included, limit: selectedCount || undefined }}
					loading={loading}
					isPipeModalOpen={isPipeModalOpen}
					openPipeModal={openPipeModal}
					closePipeModal={closePipeModal}
					linkPipe={linkPipe}
				/>
			</Menu.Item>
			<Menu.Item key="3">
				<ExportHubspotButton
					type="ghost"
					handleHubspotData={handleHubspotData}
					disabled={!someSelected}
					candidates={{ count, profile_id_list: included, limit: selectedCount || undefined }}
					loading={loading}
					isHubspotModalOpen={isHubspotModalOpen}
					openHubspotModal={openHubspotModal}
					closeHubspotModal={closeHubspotModal}
					linkHubspot={linkHubspot}
				/>
			</Menu.Item>
			<Menu.Item key="4">
				<IntergationsPushMenu
					// projectId={1}
					type="ghost"
					candidates={{
						count,
						profile_id_list: included,
						limit: selectedCount,
						exclude_ids: excluded,
						filters: modifiedFilters || undefined
					}}
					disabled={!someSelected}
				/>
			</Menu.Item>
		</Menu>
	);

	return (
		<section className={styles.container}>
			<div className={styles.info}>
				<Text variant="grotesk/14/bold">
					{data.count
						? `${formatNumberWithCommas(data.count)} leads`
						: `More than ${formatNumberWithCommas(totalLoaded)} leads`}
				</Text>
				<div className={styles.infoButtons}>
					<div className={styles.infoButtons}>
						<Button type="default" onClick={handleRevealPage}>
							Reveal this page
						</Button>
						<div>
							<Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
								<Button
									type="primary"
									onClick={handleExportButtonClick}
									className={styles.project}
									suffix={<Icon icon="arrow-down" />}>
									Export
								</Button>
							</Dropdown>
						</div>
					</div>
				</div>
			</div>
			<Table className={styles.list} ref={ref}>
				<div className={styles.header}>
					<div className={styles.listControl}>
						<TableSelection
							controls={{
								main: { togglePage, count, isAllSelected },
								extra: { selectPage, selectAll, selectCount }
							}}
							hint="Select leads"
							total={data?.count}
							countValue={countValue}
							setCountValue={setCountValue}
						/>
						<div className={styles.listControlInline}>
							<div className={styles.listControlReveal}>
								<Checkbox
									onChange={handleCheckReveal}
									checked={isHideRevealChecked}
								/>
								<Text variant="inter/13/regular">Hide revealed contacts</Text>
							</div>
							<Pagination
								page={filters.page}
								onChange={p => search({ ...filters, page: p })}
								onPageCount={data.results.length}
								total={data.count}
							/>
						</div>
					</div>
					<Tr>
						<Th width="48px" />
						<Th width="14%">Name</Th>
						<Th width="18%">Title</Th>
						<Th width="16%">Company</Th>
						<Th width="14%">Location</Th>
						<Th width="12%">Country</Th>
						<Th width="26%">Email</Th>
						<Th />
					</Tr>
				</div>
				{data.results.map((candidate, idx) => (
					<ProfileRow
						checkbox={{
							checked: isSelected({ row: candidate, page: filters.page - 1, idx }),
							onChange: () => toggle({ row: candidate, page: filters.page - 1, idx }),
							mode
						}}
						key={candidate.id}
						onClick={() =>
							setCandidate({
								match_metadata: {
									weight: 1,
									match_score: '99'
								},
								profile: candidate
							})
						}
						candidate={{
							match_metadata: {
								weight: 1,
								match_score: '99'
							},
							profile: candidate
						}}
					/>
				))}
			</Table>
			{candidate && <CandidateModal candidate={candidate} onClose={closeCandidateModal} />}
			{isCsvExportSuccessModalOpen && <SuccessModal handleClose={closeCsvExportSuccessModal} />}
			{isRevealModalOpen && (
				<BulkContactsModal
					onClose={closeRevealModal}
					ids={data.results
						.filter(candidate => candidate.emails_cleaned == null)
						.map(candidate => candidate.id.toString())}
				/>
			)}
		</section>
	);
};
